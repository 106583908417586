import styled from "styled-components";
import React, { useEffect, useState } from "react";
import ImageStaticMap from "../Media/ImageStaticMap";
import axios from "axios";

const CourtsList = ({ district, state }: { district?: string; state?: string }) => {
  if (district == null && state == null) return null;
  const [data, setData] = useState<Record<string, any>>();

  const getCourtsData = async () => {
    if (data) {
      return;
    }

    const response = await axios.get(`${UPSOLVE_API_URL}/v1/courts`, {
      params: {
        withGraph: "[addresses,district]",
        orderBy: "createdAt",
      },
    });
    setData(response.data.courts);
  };

  useEffect(() => {
    getCourtsData();
  }, []);

  if (!data) {
    return <div></div>;
  }

  return (
    <div>
      {data
        .filter((c: any) => {
          if (c.addresses && c.addresses[0] && state) return c.addresses[0].state.match(new RegExp(state, "i"));
          if (c.district && c.district.abbrev && district) return c.district.abbrev.match(new RegExp(district, "i"));
          return false;
        })
        .map((c: any) => (
          <CourtListItem key={c.name}>
            <ImageStaticMap alt={c.name} {...c.addresses?.[0]} height={120} width={120} zoom={11} />
            <p>
              <b>
                <a href={c.url}>{c.name}</a>
              </b>
              <br />
              {c.phoneNumber}
              <br />
              {c.addresses[0].street1} {c.addresses[0].street2} {c.addresses[0].city}, {c.addresses[0].state}{" "}
              {c.addresses[0].zipcode}
            </p>
          </CourtListItem>
        ))}
    </div>
  );
};

const CourtListItem = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 1em;
  & > img {
    height: 120px;
    width: 120px;
    max-width: 120px;
    border-radius: 8px;
  }
  p {
    margin: 1em;
  }
`;

export default CourtsList;
